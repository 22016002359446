import React, { useState, useEffect } from "react";
import "./checkout-navbar.scss";
import gc_logo from "../../assets/gocompare-logo.webp";
import { useNewCheckoutContext } from "../../context/checkout-context-v2";
import { useProductsContext } from "../../context/products-context";
import { checkTheme } from "../../utils/informations"

const CheckoutNavbar = ({ firstStep, secondStep, thirdStep, checkoutType }) => {

    const { checkoutStep, Referral } = useNewCheckoutContext();
    const { step } = useProductsContext();
    const [stepNav, setStepNav] = useState(0);


    useEffect(() => {
        checkoutType == "wedding" ? setStepNav(step) : setStepNav(checkoutStep)

    },);


    let organisationInfo;

    if (typeof window !== "undefined" && window.sessionStorage.getItem("products")) {
        organisationInfo = JSON.parse(sessionStorage.getItem("products"));
    }

    //console.log(Referral, "test")
    return (

        <div>
            {
                checkTheme().theme == "lcs" ?
                    Referral != '' ?

                        <div className="checkout-logos">
                            <div className="logos-content">
                                <div className="logos-alignment">
                                    <div className="inline-elements">
                                        <div className="gc-logo" id="responsive-section">
                                            <a href="https://www.gocompare.com/" target="_blank">
                                                <img src={gc_logo}
                                                    //width="215" 
                                                    alt="page logo" className="gc-logo-img" />
                                                <p className="font-title">Warranty Insurance</p>
                                            </a>{" "}
                                        </div>

                                        <div className="logo-lcs-inline">
                                            <div className="partner-content">
                                                <p className="theme-text">Powered by</p>
                                                <div className="partner-logo">
                                                    <a href="https://loyaltycoversoftware.com/" target="_blank">
                                                        <img src={checkTheme().logo}
                                                            //width="auto" 
                                                            alt="page logo" className="logo-height" />
                                                        {/* <img src={checkTheme().logo_white} width="150" alt="page logo" /> */}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        organisationInfo != undefined ?
                                            organisationInfo?.OrganisationLogo && organisationInfo?.OrganisationLogo != "" ?
                                                <div className="logo-organisation" id="responsive-section">
                                                    <div className="partner-content">
                                                        <p className="theme-text"
                                                            id="partner-theme-text"
                                                        >In association with</p>
                                                        <div className="partner-logo">
                                                            <img src={organisationInfo?.OrganisationLogo} alt={organisationInfo?.OrganisationName} className="logo-height" />
                                                            {/* <img src={checkTheme().logo_white} width="150" alt="page logo" /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                : ""
                                            : ""
                                    }

                                    <div className="logo-lcs">
                                        <div className="partner-content">
                                            <p className="theme-text">Powered by</p>
                                            <div className="partner-logo">
                                                <a href="https://loyaltycoversoftware.com/" target="_blank">
                                                    <img src={checkTheme().logo} width="auto" alt="page logo" className="logo-height" />
                                                    {/* <img src={checkTheme().logo_white} width="150" alt="page logo" /> */}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="checkout-logos" id="default-checkout-logos">
                            <div className="logos-content" style={{ padding: "0px" }}>
                                <div className="logos-alignment" style={{ maxWidth: "1200px", margin: "0 auto", padding: "5px 1rem" }}>


                                    <div className="logo-lcs">
                                        <div className="partner-content">
                                            {/* <p className="theme-text">Powered by</p> */}
                                            <div className="partner-logo">
                                                <a href="https://loyaltycoversoftware.com/" target="_blank">
                                                    <img src={checkTheme().logo}
                                                        //width="auto" 
                                                        alt="page logo" className="logo-height" />
                                                    {/* <img src={checkTheme().logo_white} width="150" alt="page logo" /> */}
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        organisationInfo != undefined ?
                                            organisationInfo?.OrganisationLogo && organisationInfo?.OrganisationLogo != "" ?
                                                <div className="logo-organisation" id="responsive-section">
                                                    <div className="partner-content">
                                                        <p className="theme-text"
                                                            id="partner-theme-text"
                                                        >In association with</p>
                                                        <div className="partner-logo">
                                                            <img src={organisationInfo?.OrganisationLogo} alt={organisationInfo?.OrganisationName} className="logo-height" />
                                                            {/* <img src={checkTheme().logo_white} width="150" alt="page logo" /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                : ""
                                            : ""
                                    }
                                </div>
                            </div>
                        </div>
                        
                    : ""
            }

            <div className="checkout-navbar">
                <div className="checkout-content">
                    <div className="checkout-header" id={checkTheme().theme == "lcs" ? "custom-header" : ""}>
                        {
                            checkTheme().theme !== "lcs" ?
                                <div className="uk-navbar-right" style={{ marginLeft: "0px" }}>
                                    <a href={checkTheme().theme == "lcs" ? "" : "/"}>
                                        <img src={checkTheme().logo_white} width="300" alt="page logo" />
                                    </a>{" "}
                                </div>
                                : ""
                        }

                        {
                            checkTheme().theme !== "lcs" ?
                                // organisationInfo != undefined ?
                                //     organisationInfo?.OrganisationLogo && organisationInfo?.OrganisationLogo != "" ?
                                //         <div className="checkout-header-text-lcs">
                                //             <div className="partner-content">
                                //                 <p className="theme-text">In association with</p>
                                //                 <div className="partner-logo">
                                //                     <img src={organisationInfo?.OrganisationLogo} width="200" alt={organisationInfo?.OrganisationName} />
                                //                     {/* <img src={checkTheme().logo_white} width="150" alt="page logo" /> */}
                                //                 </div>
                                //             </div>
                                //         </div>
                                //         : ""
                                //     :
                                <div className="checkout-header-text">
                                    <div><span className="" uk-icon="check"></span>
                                        <p>FSCS protection</p></div>
                                    <div><span className="" uk-icon="check"></span>
                                        <p>Peace of mind</p></div>
                                    <div><span className="" uk-icon="check"></span>
                                        <p>FCA Regulated</p></div>
                                </div>
                                :
                                // <div className="checkout-header-text">
                                //     <div><span className="" uk-icon="check"></span>
                                //         <p>FSCS protection</p></div>
                                //     <div><span className="" uk-icon="check"></span>
                                //         <p>Peace of mind</p></div>
                                //     <div><span className="" uk-icon="check"></span>
                                //         <p>FCA Regulated</p></div>
                                // </div>
                                ""

                        }
                    </div>
                    <div className="checkout-subheader">
                        <h3>Checkout</h3>
                        <div className="checkout-steps">
                            <div className={`checkout-step step-one ${stepNav >= 1 ? 'background-green' : ''}`}>
                                <div className="checkout-circle">
                                    {

                                        stepNav >= 1 ? <span className=" green-check-icon" uk-icon="check"></span> : <span className="step-number">1</span>

                                    }
                                </div>
                                <p>{firstStep}</p>
                            </div>
                            <div className={`checkout-step step-two ${stepNav >= 2 ? 'background-green' : ''}`}>
                                <div className="checkout-circle">
                                    {

                                        stepNav >= 2 ? <span className=" green-check-icon" uk-icon="check"></span> : <span className="step-number">2</span>

                                    }
                                </div>
                                <p>{secondStep}</p>
                            </div>
                            <div className={`checkout-step step-three ${stepNav >= 3 ? 'background-green' : ''}`}>
                                <div className="checkout-circle">
                                    {

                                        stepNav >= 3 ? <span className="green-check-icon" uk-icon="check"></span> : <span className="step-number">3</span>

                                    }
                                </div>
                                <p>{thirdStep}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default CheckoutNavbar;