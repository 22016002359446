import React, { useEffect, useState } from 'react'
import Layout from '../layouts'
import VehicleCheckoutContent from "../components/vehicle-checkout-components/vehicle-checkout-components";
import { checkTheme } from '../utils/informations'

const CheckoutForms = () => {

     //andrei: pointless refresh on /checkout
     useEffect(() => {

        if (!window.location.hash && window.location.pathname === "/checkout" && typeof window !== "undefined" && window.sessionStorage.getItem('quoteDetailsVehicle')) {
            window.location = window.location + '#loaded';
            window.location.reload();
            //Andrei: this is pointless but it force the page to reload which loads the context out and show the quote details on vehicle- details component!!! weird
            console.log("[search]4");
            // getQuote();
        }
    }, [])
    
    return (
        <Layout pageTitle={`Checkout - ${checkTheme().pageTitle}`}>
            <VehicleCheckoutContent />
            

        </Layout>
    )
}

export default CheckoutForms
