import React, { useEffect, useState, useRef } from 'react'
import ReactDatePicker from 'react-datepicker';

import { FaInfoCircle } from 'react-icons/fa';
import { FaChevronRight, FaSearch, FaSpinner } from 'react-icons/fa';

import { useProductsContext } from '../../context/products-context';
import Button from '../button/button';
import './personal-details.scss'
import { validateMobile } from '../../utils/custom-functions';
import { Link } from 'gatsby';
import "react-datepicker/dist/react-datepicker.css";

import ToggleSwitch from '../basic-components/toggle-switch/toggle-switch';

import { checkTheme } from '../../utils/informations'

const PersonalDetails = () => {


  let { increaseStep, addressArray, searchPostcode, searchPostcodeInProgress, updatePersonalDetails, updatePersonalDetailsTown, getDate, finEventsIdd, personalDetails: { firstCeremonyParticipantForname, firstCeremonyParticipantSurname, firstCeremonyParticipantDob, firstParticipantUkResidentAndRegistered, secondCeremonyParticipantsFirstname, secondCeremonyParticipantsSurname, secondCeremonyParticipantsDob, secondParticipantUkResidentAndRegistered, purchaser, relationWithParticipants, purchaserFirstname, purchaserSurname, purchaserDob, houseNumber, postcode, address, telephoneNumber, emailAddress, fullAddress }, weddingProductsSuccess, finIddResult,
    weddingDetails: { ceremonyAndReception, ceremonialSwords, ceremonyType, covidCover, guestsNumber, location, marqueeCover, marqueeCoverAmount, marqueeCoverDaysNumber, monetaryLiabilityCover, publicLiabilityCover, publicLiabilityLevel, reception, receptionDate, totalCost, weddingDate }, updateEventDetails, updateEventToggle, weddingProductsLoading, getEventsQuote } = useProductsContext()

  const [error, setError] = useState({})
  let storedState;


  useEffect(() => {
    finEventsIdd()
  }, [])

  if (typeof window !== 'undefined' && window.sessionStorage.getItem('manualAddress')) {
    storedState = JSON.parse(sessionStorage.getItem('manualAddress'));
  }


  const [addressManually, setAddressManually] = useState(storedState === true)


  useEffect(() => {
    if (typeof window !== 'undefined') {
      sessionStorage.setItem('manualAddress', JSON.stringify(addressManually))
    }
  }, [addressManually])

  useEffect(() => {
    window.localStorage.setItem(
      "weddingDetails",
      JSON.stringify({
        ceremonyType,
        location,
        weddingDate,
        reception,
        ceremonyAndReception,
        receptionDate,
        totalCost,
        publicLiabilityCover,
        publicLiabilityLevel,
        guestsNumber,
        marqueeCover,
        marqueeCoverDaysNumber,
        marqueeCoverAmount,
        ceremonialSwords,
        covidCover,
        monetaryLiabilityCover,
      })
    );
  }, [
    ceremonyType,
    location,
    weddingDate,
    reception,
    ceremonyAndReception,
    receptionDate,
    totalCost,
    publicLiabilityCover,
    publicLiabilityLevel,
    guestsNumber,
    marqueeCover,
    marqueeCoverDaysNumber,
    marqueeCoverAmount,
    ceremonialSwords,
    covidCover,
    monetaryLiabilityCover,
  ]);

  const [checked, setChecked] = useState(typeof window !== 'undefined' ? JSON.parse(sessionStorage.getItem('privacyCheckbox')) : false);
  const [checkedMed, setCheckedMed] = useState(typeof window !== 'undefined' ? JSON.parse(sessionStorage.getItem('medicalBox')) : false);
  const [checkedMed2, setCheckedMed2] = useState(typeof window !== 'undefined' ? JSON.parse(sessionStorage.getItem('medicalBox2')) : false);


  const checkDatesLimit = (receptionDate, weddingDate) => {
    const uniTime = Math.abs(receptionDate - weddingDate);
    return Math.ceil(uniTime / (1000 * 60 * 60 * 24));
  }

  const validate = () => {
    let medicalConditionStep = ''
    let medicalConditionStep2 = ''
    let mandatoryCheckboxError = ''
    let firstCeremonyParticipantFornameError = ""
    let firstCeremonyParticipantSurnameError = ""
    let firstCeremonyParticipantDobError = ""
    let firstParticipantUkResidentAndRegisteredError = ""
    let secondCeremonyParticipantsFirstnameError = ""
    let secondCeremonyParticipantsSurnameError = ""
    let secondCeremonyParticipantsDobError = ""
    let secondParticipantUkResidentAndRegisteredError = ""
    let purchaserError = ""
    let relationWithParticipantsError = ""
    let purchaserFirstnameError = ""
    let purchaserSurnameError = ""
    let postcodeError = ""
    let addressError = ""
    let fullAddressError = ""
    let houseNumberError = ""
    let telephoneNumberError = ""
    let emailAddressError = ""
    let ceremonyTypeError = ""
    let locationError = ""
    let weddingDateError = ""
    let receptionError = ""
    let publicLiabilityCoverError = ""
    let marqueeCoverError = ""
    let ceremonialSwordsError = ""
    let publicLiabilityLevelError = ""
    let marqueeCoverAmountError = ""
    let totalCostError = ""
    let datesLimitError = ''
    let x;

    if (!firstCeremonyParticipantForname) {
      firstCeremonyParticipantFornameError = "Forname must not be empty"

    }
    if (!firstCeremonyParticipantSurname) {
      firstCeremonyParticipantSurnameError = "Surname must not be empty"

    }
    if (!firstCeremonyParticipantDob) {
      firstCeremonyParticipantDobError = "Date of birth is not valid"

    }



    if (!secondCeremonyParticipantsFirstname) {
      secondCeremonyParticipantsFirstnameError = "First Name must not be empty"

    }
    if (!secondCeremonyParticipantsSurname) {
      secondCeremonyParticipantsSurnameError = "Surname must not be empty"

    }
    if (!secondCeremonyParticipantsDob) {

      secondCeremonyParticipantsDobError = "Date of birth is not valid"

    }


    if (purchaser === false && !relationWithParticipants) {
      relationWithParticipantsError = "Relation is not valid"

    }
    if (purchaser === false && !purchaserFirstname) {
      purchaserFirstnameError = "First name must not be empty"

    }
    if (purchaser === false && !purchaserSurname) {
      purchaserSurnameError = "First name must not be empty"

    }
    if (!postcode) {

      postcodeError = "Mobile phone is not valid"

    }
    if (!address && addressManually) {

      addressError = "Address must not be empty"

    }
    if (!fullAddress && !addressManually) {

      fullAddressError = "Address must not be empty"

    }
    if (!houseNumber) {

      houseNumberError = "Town must not be empty"

    }
    if (!validateMobile(telephoneNumber)) {

      telephoneNumberError = "Mobile phone is not valid"

    }
    if (!emailAddress) {

      emailAddressError = "Mobile phone is not valid"

    }

    if (!checked) {
      mandatoryCheckboxError = "Checkbox phone is not valid"

    }

    if (checkedMed) {
      medicalConditionStep = "Not eligible."
    }

    if (checkedMed2) {
      medicalConditionStep2 = "Not eligible."
    }


    if (!ceremonyType) {
      ceremonyTypeError = "Forname must not be empty";

    }


    if (!weddingDate) {
      weddingDateError = "Wedding date must not be empty";

    }



    if (publicLiabilityCover === true && !publicLiabilityLevel) {
      publicLiabilityLevelError = "Public liability level must not be empty";

    }
    if (marqueeCover === true && !marqueeCoverAmount) {
      marqueeCoverAmountError = "Marquee cover amount must not be empty";

    }

    if (reception === true && ceremonyAndReception === false && checkDatesLimit(receptionDate, weddingDate) > 21) {
      datesLimitError = 'The gap between the wedding date and reception date cannot be more than 21 days.'

    }
    if (!totalCost) {
      totalCostError = "Total cost must not pe empty"
    }

    //console.log("....................." + x)


    if (
      firstCeremonyParticipantFornameError ||
      firstCeremonyParticipantSurnameError ||
      firstCeremonyParticipantDobError ||
      firstParticipantUkResidentAndRegisteredError ||
      secondCeremonyParticipantsFirstnameError ||
      secondCeremonyParticipantsSurnameError ||
      secondCeremonyParticipantsDobError ||
      secondParticipantUkResidentAndRegisteredError ||
      purchaserError ||
      relationWithParticipantsError ||
      purchaserFirstnameError ||
      purchaserSurnameError ||
      postcodeError ||
      addressError ||
      fullAddressError ||
      houseNumberError ||
      telephoneNumberError ||
      emailAddressError ||
      medicalConditionStep ||
      medicalConditionStep2 ||
      mandatoryCheckboxError ||
      ceremonyTypeError ||
      locationError ||
      weddingDateError ||
      receptionError ||
      publicLiabilityCoverError ||
      marqueeCoverError ||
      ceremonialSwordsError ||
      publicLiabilityLevelError ||
      marqueeCoverAmountError ||
      totalCostError ||
      datesLimitError
    ) {
      setError({
        firstCeremonyParticipantFornameError,
        firstCeremonyParticipantSurnameError,
        firstCeremonyParticipantDobError,
        firstParticipantUkResidentAndRegisteredError,
        secondCeremonyParticipantsFirstnameError,
        secondCeremonyParticipantsSurnameError,
        secondCeremonyParticipantsDobError,
        secondParticipantUkResidentAndRegisteredError,
        purchaserError,
        relationWithParticipantsError,
        purchaserFirstnameError,
        purchaserSurnameError,
        postcodeError,
        addressError,
        fullAddressError,
        houseNumberError,
        telephoneNumberError,
        emailAddressError,
        medicalConditionStep,
        medicalConditionStep2,
        mandatoryCheckboxError,
        ceremonyTypeError,
        locationError,
        weddingDateError,
        receptionError,
        publicLiabilityCoverError,
        marqueeCoverError,
        ceremonialSwordsError,
        publicLiabilityLevelError,
        marqueeCoverAmountError,
        totalCostError,
        datesLimitError
      })
      return false
    }

    return true

  }

  const getEvents = async () => {
    if (!finIddResult) {
      await finEventsIdd();
    }
    let validator = validate();

    // console.log(validator, "validator test")

    if (validator) {
      await getEventsQuote();
      increaseStep();
    }
    else {
      const errorFields = document.getElementsByClassName("red-border");
      const errorTC = document.getElementsByClassName("error-data")
      const errorMedical = document.getElementById("medicalCondition")

      if (errorFields.length > 0) {
        errorFields[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
      }

      if (errorTC.length > 0) {
        errorTC[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
      }

      if (errorMedical) {
        errorMedical.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
      }
    }


    if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

      window.gtag('event', 'wedding_checkout_first_step', { 'event_category': 'ecommerce', 'event-label': 'Completed' });

    }
  };





  const checkPersonalDetails = (e) => {

    e.preventDefault()
    let validator = validate()

    if (validator) {
      increaseStep()
      sessionStorage.setItem('weddingPersonalDetails',
        JSON.stringify({ firstCeremonyParticipantForname, firstCeremonyParticipantSurname, firstCeremonyParticipantDob, firstParticipantUkResidentAndRegistered, secondCeremonyParticipantsFirstname, secondCeremonyParticipantsSurname, secondCeremonyParticipantsDob, secondParticipantUkResidentAndRegistered, purchaser, relationWithParticipants, purchaserFirstname, purchaserSurname, purchaserDob, houseNumber, postcode, address, telephoneNumber, emailAddress, fullAddress }))
      // navigate('#event-details')

    }

  }

  useEffect(() => {
    if (typeof window !== 'undefined') {

      sessionStorage.setItem('privacyCheckbox', JSON.stringify(checked))
      sessionStorage.setItem('medicalBox', JSON.stringify(checkedMed))
      sessionStorage.setItem('medicalBox2', JSON.stringify(checkedMed2))

    }
  }, [addressManually, checked, checkedMed, checkedMed2])



  // const [toggleSwitch, setToggleSwitch] = useState(false)

  // const toggleBtn = () => {
  //   setToggleSwitch(!toggleSwitch)
  // }


  const updateAddressAndTown = (e) => {

    updatePersonalDetails(e)

    if (e.target.value && !addressManually) {
      houseNumber = e.target.value.split(',')[e.target.value.split(',').length - 2].trim()
      //debugger;
      updatePersonalDetailsTown("houseNumber", houseNumber)

    }

  }

  const searchPostcodeFunction = () => {
    setAddressManually(false)
    searchPostcode()
  }


  return (

    <div className='wedding_container' >

      <div className="wedding-details-container">
        <h2 className='wedding-heading ' id="ceremony-title">Ceremony participants</h2>
      </div>

      <form data-uk-grid className='uk-child-width-1-2@s wedding-details-container '>
        {/* <div className="uk-width-1-1">
          <h2 className='wedding-heading '>Ceremony participants</h2>
        </div> */}

        <div className="first-participant">
          <h3 className="participant-title">Participant 1</h3>

          <div className='wedding-details-input'>
            <div className="placeholder" data-placeholder="First Name">
              {/* <label className='wedding-label uk-form-label' htmlFor="ceremonyType" >What is the first ceremony participant's forename name?</label> */}
              <input type="text" className={`uk-input wedding-single-input ${error.firstCeremonyParticipantFornameError ? 'red-border' : ""}`} name='firstCeremonyParticipantForname' value={firstCeremonyParticipantForname} onChange={updatePersonalDetails}
              //placeholder="First Name" 
              />
            </div>
          </div>

          <div className='wedding-details-input'>
            <div className="placeholder" data-placeholder="Last Name">
              {/* <label className='wedding-label uk-form-label' htmlFor="location">What is the first ceremony participant's surname?</label> */}
              <input type='text' className={`uk-input wedding-single-input ${error.firstCeremonyParticipantSurnameError && 'red-border'}`} name='firstCeremonyParticipantSurname' value={firstCeremonyParticipantSurname} onChange={updatePersonalDetails}
              //placeholder="Last Name" 
              />
            </div>
          </div>

          <div className='wedding-details-input'>
            <div className="placeholder" data-placeholder="Date of birth">
              {/* <label className='wedding-label uk-form-label'>What is the first ceremony participant's date of birth?</label> */}
              <ReactDatePicker selected={firstCeremonyParticipantDob == null ? firstCeremonyParticipantDob : firstCeremonyParticipantDob.getYear() == 70 ? new Date() : firstCeremonyParticipantDob} className={`uk-input wedding-single-input ${error.firstCeremonyParticipantDobError && 'red-border'}`} onSelect={(date) => getDate(date, 'firstCeremonyParticipantDob')} dateFormat="dd/MM/yyyy" id={'firstCeremonyParticipantDob'} maxDate={new Date()}
              //placeholderText="Date of birth"
              />
            </div>
          </div>



          <div className='wedding-details-input'>
            {/* <label className='wedding-label uk-form-label' htmlFor="reception">Are you a UK resident and registered with a UK GP?</label> */}

            <div className="toggle-location">
              <ToggleSwitch value={firstParticipantUkResidentAndRegistered} changeState={updateEventToggle} name="firstParticipantUkResidentAndRegistered" />
              <label className='wedding-label-location uk-form-label' htmlFor="reception">Are you a UK resident and registered with a UK GP? </label>
            </div>



            {/* <select name="firstParticipantUkResidentAndRegistered" className={`uk-select wedding-single-input ${error.firstParticipantUkResidentAndRegisteredError && 'red-border'}`} value={firstParticipantUkResidentAndRegistered} onChange={updatePersonalDetails}>
              <option selected="true" value=""  >Please select an option</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select> */}
          </div>
        </div>



        <div className="second-participant">
          <h3 className="participant-title">Participant 2</h3>
          <div className='wedding-details-input'>
            <div className="placeholder" data-placeholder="First Name">
              {/* <label className='wedding-label uk-form-label' htmlFor="">What is second ceremony participants first name?</label> */}
              <input type="text" name='secondCeremonyParticipantsFirstname' className={`uk-input wedding-single-input ${error.secondCeremonyParticipantsFirstnameError && 'red-border'}`} value={secondCeremonyParticipantsFirstname} onChange={updatePersonalDetails}
              //placeholder="First Name" 
              />
            </div>
          </div>
          <div className='wedding-details-input'>
            <div className="placeholder" data-placeholder="Last Name">
              {/* <label className='wedding-label uk-form-label' htmlFor="">What is second ceremony participants surname?</label> */}
              <input type="text" name='secondCeremonyParticipantsSurname' className={`uk-input wedding-single-input ${error.secondCeremonyParticipantsSurnameError && 'red-border'}`} onChange={updatePersonalDetails} value={secondCeremonyParticipantsSurname}
              //placeholder="Last Name" 
              />
            </div>
          </div>
          <div className='wedding-details-input'>
            <div className="placeholder" data-placeholder="Date of birth">
              {/* <label className='wedding-label uk-form-label' htmlFor="">What is second ceremony participants date of birth?</label> */}
              <ReactDatePicker selected={secondCeremonyParticipantsDob == null ? secondCeremonyParticipantsDob : secondCeremonyParticipantsDob.getYear() == 70 ? new Date() : secondCeremonyParticipantsDob} className={`uk-input wedding-single-input  ${error.secondCeremonyParticipantsDobError && 'red-border'}`} dateFormat="dd/MM/yyyy" id={'secondCeremonyParticipantsDob'} onSelect={(date) => getDate(date, 'secondCeremonyParticipantsDob')} maxDate={new Date()}
              //placeholderText="Date of birth" 
              />
            </div>
          </div>
          <div className='wedding-details-input'>
            {/* <label className='wedding-label uk-form-label' htmlFor="">Are they a UK resident and registered with a UK GP?</label>
            <select name="secondParticipantUkResidentAndRegistered" className={`uk-select wedding-single-input ${error.secondParticipantUkResidentAndRegisteredError && 'red-border'}`} value={secondParticipantUkResidentAndRegistered} onChange={updatePersonalDetails}>
              <option selected="true" value="" >Please select an option</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>

            </select> */}

            <div className="toggle-location">
              <ToggleSwitch value={secondParticipantUkResidentAndRegistered} changeState={updateEventToggle} name="secondParticipantUkResidentAndRegistered" />
              <label className='wedding-label-location uk-form-label' htmlFor="reception">Are you a UK resident and registered with a UK GP?</label>
            </div>

          </div>
        </div>

        {
          (!secondParticipantUkResidentAndRegistered && !firstParticipantUkResidentAndRegistered) ?
            <div className="residence-condition uk-alert uk-alert-warning" uk-alert="true" id="residence-padding">
              <a className="uk-alert-close" uk-close></a>
              <p>Is at least one of the Marrying Couple to be insured Resident in the United Kingdom? If not, please contact {checkTheme().pageTitle} on <a href={`tel:${checkTheme().phone}`} className="overlap">{checkTheme().displayPhone}</a>.</p>
              <p>Are you aware, at the time of issue of this insurance, of any reason why the Wedding plans may be cancelled or amended? If so, please contact {checkTheme().pageTitle} on <a href={`tel:${checkTheme().phone}`} className="overlap">{checkTheme().displayPhone}</a>.</p>
            </div>
            :
            ""
        }

        <div className="medical-conditions">

          <div className="toggle-location">
            <ToggleSwitch value={checkedMed} changeState={() => setCheckedMed(!checkedMed)} name="purchaser" />
            <label className={error.medicalConditionStep ? "error" : 'wedding-label-location uk-form-label'} htmlFor="reception" id={error.medicalConditionStep ? "medicalCondition" : ""}>Do any of you have any medical condition for which you have received a terminal diagnosis?</label>
          </div>

          <div className="toggle-location">
            <ToggleSwitch value={checkedMed2} changeState={() => setCheckedMed2(!checkedMed2)} name="purchaser" />
            <label className={error.medicalConditionStep2 ? "error" : 'wedding-label-location uk-form-label'} htmlFor="reception" id={error.medicalConditionStep ? "medicalCondition" : ""}>Are any of you waiting for or undergoing treatment or investigation at a hospital or specialist clinic or awaiting the results of any tests or investigation?</label>
          </div>

          {
            (checkedMed || checkedMed2) ?
              <div className="uk-alert uk-alert-danger" uk-alert="true" id="residence-padding">
                <a className="uk-alert-close" uk-close></a>
                <p> There is no cover for any claims related directly or indirectly to these conditions.</p>
              </div>
              :
              ""
          }


        </div>




      </form>



      <div id="policyholder-background">
        {/* <h2 className='wedding-heading '>Policyholder details</h2> */}
        <form className="uk-grid-small wedding-details-container" uk-grid="true">
          <div className="policyholder-column uk-width-1-1">
            <h2 className='wedding-heading '>Policyholder details</h2>
          </div>



          <div className='wedding-details-input uk-width-1-2@s'>
            {/* <label className='wedding-label uk-form-label' htmlFor="">Are you purchasing this policy for yourself or on behalf of other participants?</label>
          <select name="purchaser" className={`uk-select wedding-single-input  ${error.purchaserError && 'red-border'}`} value={purchaser} onChange={updatePersonalDetails}>
            <option selected="true" value="" >Please select an option</option>
            <option value={'yourself'}>Yourself</option>
            <option value={'others'}>Others</option>
          </select> */}

            <div className="toggle-location">
              <ToggleSwitch value={purchaser} changeState={updateEventToggle} name="purchaser" />
              <span className='wedding-label-location uk-form-label' htmlFor="reception">Are you purchasing this policy for yourself?</span>
            </div>

          </div>

          {
            !(purchaser) && (<>


              <div className='wedding-details-input uk-width-1-2@s'>
                <div className="placeholder" data-placeholder="What is your relationship to the participants?">
                  {/* <label className='wedding-label uk-form-label' htmlFor="">What is your relationship to the participants?</label> */}
                  <select name="relationWithParticipants" className={`uk-select wedding-single-input  ${error.relationWithParticipantsError && 'red-border'}`} value={relationWithParticipants} onChange={updatePersonalDetails}>
                    <option value="" disabled selected>-</option>
                    <option value={'parent'}>Parent</option>
                    <option value={'grandParent'}>Grand Parent</option>
                    <option value={'legalGuardian'}>Legal Guardian</option>
                    <option value={'relation'}>Relation</option>
                    <option value={'other'}>Other</option>

                  </select>
                </div>
              </div>


              <div className='wedding-details-input uk-width-1-2@s'>
                <div className="placeholder" data-placeholder="Your first name, as the purchaser of this policy">
                  {/* <label className='wedding-label uk-form-label' htmlFor="">
                <button className="tooltip-btn" data-uk-tooltip="title: As the purchaser of this policy; pos: top-right"><AiOutlineInfoCircle /></button>
              </label> */}
                  <input type="text" name='purchaserFirstname' className={`uk-input wedding-single-input  ${error.purchaserFirstnameError && 'red-border'}`} value={purchaserFirstname} onChange={updatePersonalDetails}
                  //placeholder="Your first name, as the purchaser of this policy" 
                  />
                </div>
              </div>

              <div className='wedding-details-input uk-width-1-2@s'>
                <div className="placeholder" data-placeholder="Your last name, as the purchaser of this policy">
                  {/* <label className='wedding-label uk-form-label' htmlFor="">What is your surname?
                <button className="tooltip-btn" data-uk-tooltip="title: As the purchaser of this policy; pos: top-right"><AiOutlineInfoCircle /></button>
              </label> */}
                  <input type="text" name='purchaserSurname' className={`uk-input wedding-single-input  ${error.purchaserSurnameError && 'red-border'}`} value={purchaserSurname} onChange={updatePersonalDetails}
                  //placeholder="Your last name, as the purchaser of this policy" 
                  />
                </div>
              </div>

              <div className='wedding-details-input uk-width-1-2@s'>
                <div className="placeholder" data-placeholder="Date of birth">
                  {/* <label className='wedding-label uk-form-label'>What is your date of birth?
                <button className="tooltip-btn" data-uk-tooltip="title: As the purchaser of this policy; pos: top-right"><AiOutlineInfoCircle /></button>
              </label> */}

                  <ReactDatePicker selected={purchaserDob == null ? purchaserDob : purchaserDob.getYear() == 70 ? new Date() : purchaserDob} dateFormat="dd/MM/yyyy" className={`uk-input wedding-single-input `} onSelect={(date) => getDate(date, 'purchaserDob')} id={'purchaserDob'} maxDate={new Date()}
                  //placeholderText="Date of birth" 
                  />
                </div>
              </div>

            </>
            )
          }


          <div className='wedding-details-input uk-width-1-2@s'>
            <div className="placeholder" data-placeholder="Email address">
              {/* <label className='wedding-label uk-form-label' htmlFor="">What is your email address? </label> */}
              <input type="email" name='emailAddress' className={`uk-input wedding-single-input  ${error.emailAddressError && 'red-border'}`} value={emailAddress} onChange={updatePersonalDetails} pattern=".+@globex\.com" required={true}
              //placeholder="Email address" 
              />
            </div>
          </div>

          <div className='wedding-details-input  uk-width-1-2@s'>
            <div className="placeholder" data-placeholder="Mobile phone">
              {/* <label className='wedding-label uk-form-label' htmlFor="">What is your telephone number?</label> */}
              <input type="text" name='telephoneNumber' className={`uk-input wedding-single-input  ${error.telephoneNumberError && 'red-border'}`} value={telephoneNumber} onChange={updatePersonalDetails}
              //placeholder="Mobile phone" 
              />
            </div>
          </div>

          <div className='wedding-details-input uk-width-1-2@s'>
            {/* <label className='wedding-label uk-form-label' htmlFor="">What is your postcode?</label> */}
            <div className="placeholder" data-placeholder="Enter your postcode">
              <div className="address_container-wedding">
                <div className="form-controls address">
                  <input type="text" name='postcode' className={`uk-input wedding-single-input ${error.postcodeError && 'red-border'}`} id="postcode" value={postcode} onChange={updatePersonalDetails}
                  //placeholder="Enter your postcode" 
                  />
                  {/* <div className="error-msg">{errors.postcodeError}</div> */}
                </div>
                <span className="icon-container" >
                  <span className="search-icon" onClick={() => searchPostcodeFunction()}>
                    {searchPostcodeInProgress ? <FaSpinner size={24} className="spiner-animation" /> : <FaSearch className="" size={24} />}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div className='wedding-details-input uk-width-1-2@s'>
            <div className="address-manual">
              {/* <label className='wedding-label uk-form-label' htmlFor="">Address</label> */}
              <span className="manually-link" onClick={() => setAddressManually(!addressManually)}>{addressManually ? 'Select address from the list' : 'Enter address manually'}</span>
            </div>



            <div>
              {
                addressManually ? (
                  <div className="placeholder" data-placeholder="Type address here">
                    <input type="text"
                      className={`uk-input wedding-single-input select-address ${error.addressError && 'red-border'}`}
                      name="address"
                      value={address}
                      onChange={updatePersonalDetails}
                    //placeholder="Address"
                    />
                  </div>
                ) : (
                  <div className="placeholder" data-placeholder="Full address">
                    <select
                      className={`uk-select wedding-single-input  select-address ${error.fullAddressError && 'red-border'}`}

                      value={fullAddress}
                      name='fullAddress'
                      onChange={(e) => updateAddressAndTown(e)}
                    >

                      {/* <option value="" disabled selected>Select an address</option> */}
                      {addressArray.map((item, key) =>
                        <option key={key} id={item} value={item} name={item}>{item}</option>
                        //console.log(item.split(',')[item.split(',').length - 2], "test split")
                      )}
                    </select>
                  </div>
                )
              }
            </div>
          </div>

          {/* {console.log(address, " < address > ", "\n", fullAddress, " < fullAddress > ", "\n", houseNumber, " < town > ")} */}

          <div className='wedding-details-input  uk-width-1-2@s'>
            {/* <label className='wedding-label uk-form-label' htmlFor="">What is your house number?
            <button className="tooltip-btn" data-uk-tooltip="title: As the purchaser of this policy; pos: top-right"><AiOutlineInfoCircle /></button>
          </label> */}
            <div className="placeholder" data-placeholder="Town">

              <input type="text" name='houseNumber' className={`uk-input wedding-single-input  ${error.houseNumberError && 'red-border'}`}
                value={
                  (fullAddress && !addressManually) ? fullAddress.split(',')[fullAddress.split(',').length - 2] :
                    houseNumber}
                onChange={updatePersonalDetails}
              //placeholder="Town" 
              />
            </div>


            {/* <input type="text" name='houseNumber' className={`uk-input wedding-single-input  ${error.houseNumberError && 'red-border'}`} 
            value={
              //fullAddress ? fullAddress.split(',')[fullAddress.split(',').length - 2] : 
            houseNumber} 
            onChange={updatePersonalDetails} placeholder="Town" /> */}
          </div>

          <div className='mandatory-checkbox uk-width-1-1'>
            {/* <input type="checkbox" id='mandatory' checked={checked} onChange={() => setChecked(!checked)} />
            <label htmlFor="mandatory" className={error.mandatoryCheckboxError ? "error" : ''}>We will ask you for personal and other information so we can provide you with an insurance quotation and policy.  We will use this information for these purposes only.  Please tick the box to indicate that you agree with us using your data this way.  Please see our full <Link to='/privacy-cookie-policy'>Privacy Policy</Link></label> */}
            <div className="toggle-location">
              <ToggleSwitch value={checked} changeState={() => setChecked(!checked)} name="purchaser" />
              <label className={error.mandatoryCheckboxError ? "error-data" : 'wedding-label-privacy uk-form-label'} htmlFor="reception">We will ask you for personal and other information so we can provide you with an insurance quotation and policy.  We will use this information for these purposes only.  Please tick the box to indicate that you agree with us using your data this way.  Please see our full <Link to='/privacy-cookie-policy'>Privacy Policy</Link>.</label>
            </div>

          </div>





        </form>
      </div>



      <form data-uk-grid className="uk-child-width-1-2@s wedding-details-container ">

        <div className="ceremony-wedding-details">
          <h2 className="wedding-heading ">Ceremony</h2>
          <div className="wedding-details-input">
            <div className="placeholder" data-placeholder="Type of ceremony">
              {/* <label className="wedding-label uk-form-label" htmlFor="ceremonyType">What type of ceremony it is?</label> */}
              <select
                name="ceremonyType"
                className={`uk-select wedding-single-input ${error.ceremonyTypeError && "red-border"
                  }`}
                id="ceremonyType"
                value={ceremonyType}
                onChange={updateEventDetails}
              >
                <option value="" disabled selected>-</option>
                <option value="marriage">Marriage</option>
                <option value="civilPartenership">Civil Partenership</option>
                <option value="civilRegistration">Civil Registration</option>
                <option value="civilCeremony">Civil Ceremony</option>
                <option value="blessing">Blessing (Non-legally binding)</option>
                <option value="vowsRenewal">Renewal of Vows (Non-legally binding)</option>
              </select>
            </div>
          </div>

          <div className="wedding-details-input">
            {/* <label className="wedding-label uk-form-label">
            What date is the ceremony?
            </label> */}
            {/* {console.log(weddingDate.getYear(), "date")} */}
            <div className="placeholder" data-placeholder="Date of ceremony">
              <ReactDatePicker
                selected={weddingDate == null ? weddingDate : weddingDate.getYear() == 70 ? new Date() : weddingDate}
                className={`uk-input wedding-single-input ${error.weddingDateError ? "red-border" : error.datesLimitError ? " red-border" : ''
                  }`}
                dateFormat="dd/MM/yyyy"
                onSelect={(date) => getDate(date, "weddingDate")}
                id={'weddingDate'}
                minDate={new Date()}
              //placeholderText="Date of ceremony"
              />
            </div>
          </div>

          <div className="wedding-details-input">
            {/* <label className="wedding-label uk-form-label">
            What is the total cost of the ceremony/reception?{" "}
            </label> */}
            <div className="placeholder" data-placeholder="What is the total cost of the ceremony/reception?">

              <div className="info-circle-input">
                <select
                  name="totalCost"
                  value={totalCost}
                  onChange={updateEventDetails}
                  className={`uk-select uk-textarea wedding-single-input ${error.totalCostError && 'red-border'}`}
                >
                  <option value="" disabled selected>
                    -
                  </option>
                  <option value="6000">less than £6,000</option>
                  <option value="10000">£6,000-£10,000</option>
                  <option value="15000">£10,000-£15,000</option>
                  <option value="20000">£15,000-£20,000</option>
                  <option value="30000">£20,000-£30,000</option>
                  <option value="50000">£30,000-£50,000</option>
                </select>


                {/* <div className="tooltip"><FaInfoCircle />
                <p className="tooltiptext">
                  <span>
                    ???
                  </span>
                </p>
              </div> */}
              </div>
            </div>



          </div>

          <div className="wedding-details-input">
            {/* <label className="wedding-label uk-form-label" htmlFor="location">
              Is it taking place in the UK?
            </label>
            <select
              name="location"
              className={`uk-select wedding-single-input ${error.locationError && "red-border"
                }`}
              value={location}
              onChange={updateEventDetails}
            >
              <option selected="true" value="">
                Please select an option
              </option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select> */}

            <div className="toggle-location">
              <ToggleSwitch value={location} changeState={updateEventDetails} name="location" />
              <label className='wedding-label-location uk-form-label' htmlFor="reception">Is the wedding taking place in the UK?</label>
            </div>

          </div>
        </div>

        <div className="public-liability-wedding">
          <h2 className="wedding-heading ">Public liability</h2>
          <div className="wedding-details-input">
            {/* <label className="wedding-label uk-form-label" htmlFor="">
              Would you like to add public liablity cover?
            </label>
            <select
              name="publicLiabilityCover"
              className={`uk-select wedding-single-input ${error.publicLiabilityCoverError && "red-border"
                }`}
              value={publicLiabilityCover}
              onChange={updateEventDetails}
            >
              <option selected="true" value="">
                Please select an option
              </option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select> */}

            <div className="toggle-location">
              <ToggleSwitch value={publicLiabilityCover} changeState={updateEventDetails} name="publicLiabilityCover" />
              <label className='wedding-label-location uk-form-label' htmlFor="reception">Would you like to extend your standard public liablity cover?
                {/* <button
                  className="tooltip-btn"
                  data-uk-tooltip="title: "
                >
                  <AiOutlineInfoCircle />
                </button> */}

                {/* <div className="tooltip"><FaInfoCircle />
                  <p className="tooltiptext">
                    <span>
                      ???
                    </span>
                  </p>
                </div> */}

              </label>
            </div>

          </div>

          {
            publicLiabilityCover === true && (
              <>
                <div className="wedding-details-input">
                  {/* <label className="wedding-label uk-form-label" htmlFor="">
                  What level of public liability do you require?
                </label> */}
                  <div className="placeholder" data-placeholder="What level of public liability do you require?">
                    <select
                      name="publicLiabilityLevel"
                      value={publicLiabilityLevel}
                      onChange={updateEventDetails}
                      className={`uk-select wedding-single-input ${error.publicLiabilityLevelError && "red-border"
                        }`}
                    >
                      <option value="" disabled selected>
                        -
                      </option>
                      <option value="couple only">Couple Only</option>
                      <option value="couple and guests">Couple and Guests</option>
                    </select>
                  </div>
                </div>


                <div className="wedding-details-input">
                  {/* <label className="wedding-label uk-form-label" htmlFor="">
                  What monetary level of public liability do you require?
                </label> */}
                  <div className="placeholder" data-placeholder="What monetary level of public liability do you require?">
                    <select
                      name="monetaryLiabilityCover"
                      value={monetaryLiabilityCover}
                      onChange={updateEventDetails}
                      className="uk-select wedding-single-input"
                    >
                      <option value="" disabled selected>
                        -
                      </option>
                      <option value="2M">£2,000,000</option>
                      {publicLiabilityLevel != "couple only" ? <option value="5M">£5,000,000</option> : ""}
                    </select>
                  </div>
                </div>
                <div className="wedding-details-input">
                  {/* <label className="wedding-label uk-form-label" htmlFor="">
                  How many guests will be attenting in total?
                </label> */}
                  <div className="placeholder" data-placeholder="How many guests will be attending in total?">
                    <select
                      name="guestsNumber"
                      value={guestsNumber}
                      onChange={updateEventDetails}
                      className="uk-select wedding-single-input"
                    >
                      <option value="" disabled selected>
                        -
                      </option>
                      <option value="1-50">1-50</option>
                      <option value="51-100">51-100</option>
                      <option value="101-200">101-200</option>
                      <option value="over 200">over 200</option>
                    </select>
                  </div>
                </div>
              </>
            )}
        </div>

      </form>


      <form data-uk-grid className="uk-child-width-1-2@s wedding-details-container " id="display-for-form">
        <div>
          <h2 className="wedding-heading ">Reception</h2>
          <div className="wedding-details-input">
            {/* <label className="wedding-label uk-form-label" htmlFor="reception">
            Will there be a reception?
            </label>
            <select
            name="reception"
            className={`uk-select wedding-single-input ${error.receptionError && "red-border"
              }`}
            value={reception}
            onChange={updateEventDetails}
            >
            <option selected="true" value="">
              Please select an option
            </option>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
            </select> */}

            <div className="toggle-location">
              <ToggleSwitch value={reception} changeState={updateEventDetails} name="reception" />
              <label className='wedding-label-location uk-form-label' htmlFor="reception">Will there be a reception?</label>
            </div>

          </div>


          {
            reception && (
              <div className="wedding-details-input">
                {/* <label className="wedding-label uk-form-label" htmlFor="">
              Are the ceremony and reception being held on the same date?
            </label>
            <select
              name="ceremonyAndReception"
              value={ceremonyAndReception}
              onChange={updateEventDetails}
              className="uk-select wedding-single-input"
            >
              <option selected="true" value="">
                Please select an option
              </option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select> */}

                <div className="toggle-location">
                  <ToggleSwitch value={ceremonyAndReception} changeState={updateEventDetails} name="ceremonyAndReception" />
                  <label className='wedding-label-location uk-form-label' htmlFor="reception">Are the ceremony and reception being held on the same date?</label>
                </div>
              </div>
            )}


          {
            !(ceremonyAndReception) && (reception) && (
              <div className="wedding-details-input">
                {/* <label className="wedding-label uk-form-label" htmlFor="">
                  What date is the reception going to be held?
                </label> */}
                <div className="placeholder" data-placeholder="What date is the reception going to be held?">
                  <ReactDatePicker
                    selected={receptionDate == null ? receptionDate : receptionDate.getYear() == 70 ? new Date() : receptionDate}
                    dateFormat="dd/MM/yyyy"
                    name="receptionDate"
                    className={error.datesLimitError ? "uk-input wedding-single-input red-border" : 'uk-input wedding-single-input'}
                    onSelect={(date) => getDate(date, "receptionDate")}
                    minDate={new Date()}
                  //placeholderText="Date of reception"
                  />
                </div>
              </div>
            )}


        </div>

      </form>




      <form data-uk-grid className="uk-child-width-1-2@s wedding-details-container " id="display-for-form">
        <div>
          <h2 className="wedding-heading ">Marquee</h2>
          <div className="wedding-details-input">

            {/* <label className="wedding-label uk-form-label" htmlFor="">
            Would you like to add marquee cover?
            <button
              className="tooltip-btn"
              data-uk-tooltip="title: Includes loss/theft or damage; pos: top-right"
            >
              <AiOutlineInfoCircle />
            </button>
          </label>
          <select
            name="marqueeCover"
            value={marqueeCover}
            onChange={updateEventDetails}
            className={`uk-select wedding-single-input ${error.marqueeCoverError && "red-border"
              }`}
          >
            <option selected="true" value="">
              Please select an option
            </option>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select> */}

            <div className="toggle-location">
              <ToggleSwitch value={marqueeCover} changeState={updateEventDetails} name="marqueeCover" />

              <label className='wedding-label-location uk-form-label' htmlFor="reception">Would you like to add marquee cover?
                {/* <button
                  className="tooltip-btn"
                  data-uk-tooltip="title: If you are holding your Wedding ceremony or reception in a standalone marquee, then it makes sense to cover this within your Wedding Insurance policy.; pos: top-right"
                >
                  <AiOutlineInfoCircle />
                </button>*/}

                <div className="tooltip"><FaInfoCircle />
                  <p className="tooltiptext">Marquee & Event Equipment
                    <span>
                      If you are holding your Wedding ceremony or reception in a standalone marquee,
                      then it makes sense to cover this within your Wedding Insurance policy.
                    </span>
                  </p>
                </div>

              </label>
            </div>

          </div>


          {

            (marqueeCover) && (
              <>
                {/* <div className="wedding-details-input">
                  <select
                    name="marqueeCoverDaysNumber"
                    value={marqueeCoverDaysNumber}
                    onChange={updateEventDetails}
                    className="uk-select wedding-single-input"
                  >
                    <option disabled selected="true" value="">
                      How many days do you require cover for the marquee?
                    </option>
                    <option value="1">1 Day</option>
                    <option value="2">2 Days</option>
                    <option value="3">3 Days</option>
                    <option value="4">4 Days</option>
                    <option value="5">5 Days</option>
                  </select>
                </div> */}

                <div className="wedding-details-input">
                  <div className="placeholder" data-placeholder="How much marquee cover do you need?">
                    <select
                      name="marqueeCoverAmount"
                      value={marqueeCoverAmount}
                      onChange={updateEventDetails}
                      className={`uk-select wedding-single-input ${error.marqueeCoverAmountError && "red-border"
                        }`}
                    >
                      <option disabled selected="true" value="">
                        -
                      </option>
                      <option value="20000">Up to £20000</option>
                      <option value="50000">Up to £50000</option>
                    </select>
                  </div>
                </div>
              </>
            )}
        </div>

      </form>

      <form data-uk-grid className="uk-child-width-1-2@s wedding-details-container " id="display-for-form">
        <div>
          <h2 className="wedding-heading ">Other</h2>
          <div className="wedding-details-input">
            {/* <label className="wedding-label uk-form-label" htmlFor="">
            Would you like to add cover for ceremonial swords?
            <button
              className="tooltip-btn"
              data-uk-tooltip="title: Includes loss/theft or damage; pos: top-right"
            >
              <AiOutlineInfoCircle />
            </button>
          </label>
          <select
            name="ceremonialSwords"
            value={ceremonialSwords}
            onChange={updateEventDetails}
            className={`uk-select wedding-single-input ${error.ceremonialSwordsError && "red-border"
              }`}
          >
            <option selected="true" value="">
              Please select an option
            </option>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select> */}

            <div className="toggle-location">
              <ToggleSwitch value={ceremonialSwords} changeState={updateEventDetails} name="ceremonialSwords" />
              <label className='wedding-label-location uk-form-label' htmlFor="reception">Would you like to add cover for ceremonial swords?
                {/* <button
                  className="tooltip-btn"
                  data-uk-tooltip="title: Includes loss/theft or damage; pos: top-right"
                >
                  <AiOutlineInfoCircle />
                </button> */}

                {/* <div className="tooltip"><FaInfoCircle />
                  <p className="tooltiptext">
                    <span>
                      ???
                    </span>
                  </p>
                </div> */}

              </label>
            </div>

          </div>


          <div className="wedding-details-input covid-cover">
            <div className="toggle-location">
              <ToggleSwitch value={covidCover} changeState={updateEventDetails} name="covidCover" />
              <label className='wedding-label-location uk-form-label' htmlFor="reception">Would you like to add COVID cover?

                {/* <button
                  className="tooltip-btn"
                  data-uk-tooltip="title: Includes loss/theft or damage; pos: top-right"
                >
                  <FaInfoCircle />
                </button> */}

                {/* <div className="tooltip"><FaInfoCircle />
                  <p className="tooltiptext">
                    <span>
                      ???
                    </span>
                  </p>
                </div> */}



              </label>
            </div>
          </div>


        </div>
      </form>






      {/* <div className="continue-button-container-1 padding-top">
        <Button btnType={"primary"} clickAction={checkPersonalDetails}>See Quote <FaChevronRight /></Button>
      </div> */}

      {error.datesLimitError ? <p className="error_msg">{error.datesLimitError} </p> : ''}

      <div className="continue-button-container-1 padding-top">
        {/* <Button btnType={"secondary"} clickAction={previousStep}>
          <FaChevronLeft /> Previous step
        </Button> */}

        <Button btnType={"primary"} clickAction={getEvents}>
          {
            weddingProductsLoading ? (

              <svg className="spinner" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth={5}></circle>
              </svg>


            ) : 'See Quote'
          } <FaChevronRight />
        </Button>
      </div>

    </div>
  )
}

export default PersonalDetails